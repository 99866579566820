<template>
  <noscript>
    <strong>We're sorry but {{ appName }} doesn't work properly without JavaScript enabled. Please enable it to continue.</strong>
  </noscript>
  <RouterView />
</template>

<script>
const pkg = require('../package.json')

export default {
  name: 'mainApp',
  data() {
    return {
      appName: pkg.name
    }
  }
}
</script>