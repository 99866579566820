<template>
    <section id="banner" v-if="!isMobile">
        <h2 class="section-title">Баннер</h2>
        <swiper class="container">
            <swiper-slide :class="`banner banner_${index}`" v-for="(slide, index) in bannerSlides" :key="index">
                <img :src="api.apiDomen+slide.imgSrc" :alt="slide.label">
                <span class="label">
                    <h1>{{ slide.label }}</h1>
                    <p v-if="slide.description != ''">{{ slide.description }}</p>
                </span>
            </swiper-slide>
        </swiper>
    </section>
</template>

<script>
    import api from '@/api';
    import { Swiper, SwiperSlide } from 'swiper/vue';

    export default {
        name: "bannerComp",
        async mounted(){
            this.bannerSlides = await api.getData('banners') 

            window.addEventListener('resize', () => this.isMobile = this.isMobileDetect())
            
        },
        data(){
            return {
                api: api,
                bannerSlides: [],
                isMobile: this.isMobileDetect(),
            }
        },
        components: {
            Swiper, 
            SwiperSlide,
        },
        methods: {
            isMobileDetect() {
                if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                    return true
                } else {
                    return false
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
@import '../assets/styles/__vars.scss';

#banner{
    background: $default-white-color-100;

    .section-title{
        display: none;
    }

    .swiper-slide{
        height: 400px;
        overflow: hidden;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        img{ 
            width: inherit;
            position: absolute;
            object-fit: cover;
        }

        span{
            position: absolute;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: $font-size-main;
            user-select: none;

            h1{
                font-size: clamp(48px, 128px, 256px);
                font-family: $font-b;
                font-style: italic;
                font-weight: 100;
            }

            p{
                font-family: $font-i;
                font-size: $font-size-main * 1.25;
            }
        }
    }
}
</style>