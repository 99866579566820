<template>
    <label for="input-file-custom" class="input-file">
        <span class="input-file-text" ref="filename"></span>
        <input @input="fileSelected" v-bind:multiple="multiple" type="file" id="input-file-custom" name="file">
        <span class="input-file-btn">{{text}}</span>
    </label>
</template>

<script>
export default {
    name: 'file-input',
    props:{
        text : {
            type : String
        },
        multiple : {
            type : Boolean
        }
    },
    methods : {
        fileSelected(event){
            if(event.target.files.length > 0){
                this.$refs['filename'].innerHTML = event.target.files[0].name
            }
            else{
                this.$refs['filename'].innerHTML = ''
            }
            this.$emit('changed',event.target.files);
        }
    }
}
</script>
