<template>
    <HeaderComp></HeaderComp>
    <main v-if="loaded" class="main">
        <BannerComp v-if="!isMobile"></BannerComp>

        <section id="services" class="dynamic">
            <h2 class="section-title">Какие перевозки мы предлагаем?</h2>

            <div class="container">
                <div class="service" v-for="(service, index) in services" :key="index">
                    <h3 class="service-title">{{ service.title }}</h3>
                    <div class="icon">
                        <img :src="api.apiDomen+service.imgSrc" :alt="service.title">
                    </div>
                </div>
            </div>
        </section>
        

        <section id="buspark" class="dynamic">
            <h2 class="section-title">Наш автопарк - к вашим услугам!</h2>

            <swiper class="container_swiper" v-if="isMobile">
                <swiper-slide :class="`bus bus_${index}`" v-for="(bus, index) in busCollection" :key="index" :ref="`bus_${index}`">
                    <swiper class="bus_images">
                        <swiper-slide v-for="(photo, index) in bus.images" :key="index">
                            <img :src="api.apiDomen+photo" alt="Фото автобуса">
                        </swiper-slide>
                    </swiper>
                    <div class="info">
                        <h3 class="bus-name">
                            {{ bus.name }}<br>
                            <span class="govNumber">{{ bus.gov_number }}</span>
                        </h3>
    
                        <ul>
                            <li>
                                <p>
                                    <!-- <i class="material-icons material-icons-filled">sticky_note2</i> -->
                                    {{ bus.description }}</p>
                            </li>
                            <li>
                                <p>
                                    <!-- <i class="material-icons material-icons-filled">event-seat</i> -->
                                    <b>Вместимость:</b>&nbsp;{{ bus.capacity }}</p>
                            </li>
                            <li>
                                <p>
                                    <!-- <i class="material-icons material-icons-filled">calendar-month</i> -->
                                    <b>Год выпуска:</b>&nbsp;{{ bus.release_year }}</p>
                            </li>
                            <li>
                                <p>
                                    <!-- <i class="material-icons material-icons-filled">business-center</i> -->
                                    <b>Багажный отсек:</b>&nbsp;{{ bus.baggage ? 'Есть' : 'Нет' }}</p>
                            </li>
                            <li>
                                <p>
                                    <!-- <i class="material-icons material-icons-filled">device-thermostat</i> -->
                                    <b>Климат-контроль:</b>&nbsp;{{ bus.climate_control ? 'Есть' : 'Нет' }}</p>
                            </li>
                            <li>
                                <p>
                                    <!-- <i class="material-icons material-icons-filled">local-offer</i> -->
                                    <b>Стоимость в час:</b>&nbsp;{{ bus.price_per_hour }}&nbsp;руб</p>
                            </li>
                            <li>
                                <p>
                                    <!-- <i class="material-icons material-icons-filled">access-time-filled</i> -->
                                    <b>Минимальный срок аренды:</b>&nbsp;{{ bus.min_time }}&nbsp;ч.</p>
                            </li>
                        </ul>
                    </div>
                </swiper-slide>
            </swiper>

            <div class="container" v-else>
                <div class="swiper-slide" :class="`bus bus_${index}`" v-for="(bus, index) in busCollection" :key="index" :ref="`bus_${index}`">
                    <swiper class="bus_images">
                        <swiper-slide v-for="(photo, index) in bus.images" :key="index">
                            <img :src="api.apiDomen+photo" alt="Фото автобуса">
                        </swiper-slide>
                    </swiper>
                    <div class="info">
                        <h3 class="bus-name">
                            {{ bus.name }}<br>
                            <span class="govNumber">{{ bus.gov_number }}</span>
                        </h3>
    
                        <ul>
                            <li>
                                <p>
                                    <!-- <i class="material-icons material-icons-filled">sticky_note2</i> -->
                                    {{ bus.description }}</p>
                            </li>
                            <li>
                                <p>
                                    <!-- <i class="material-icons material-icons-filled">event-seat</i> -->
                                    <b>Вместимость:</b>&nbsp;{{ bus.capacity }}</p>
                            </li>
                            <li>
                                <p>
                                    <!-- <i class="material-icons material-icons-filled">calendar-month</i> -->
                                    <b>Год выпуска:</b>&nbsp;{{ bus.release_year }}</p>
                            </li>
                            <li>
                                <p>
                                    <!-- <i class="material-icons material-icons-filled">business-center</i> -->
                                    <b>Багажный отсек:</b>&nbsp;{{ bus.baggage ? 'Есть' : 'Нет' }}</p>
                            </li>
                            <li>
                                <p>
                                    <!-- <i class="material-icons material-icons-filled">device-thermostat</i> -->
                                    <b>Климат-контроль:</b>&nbsp;{{ bus.climate_control ? 'Есть' : 'Нет' }}</p>
                            </li>
                            <li>
                                <p>
                                    <!-- <i class="material-icons material-icons-filled">local-offer</i> -->
                                    <b>Стоимость в час:</b>&nbsp;{{ bus.price_per_hour }}&nbsp;руб</p>
                            </li>
                            <li>
                                <p>
                                    <!-- <i class="material-icons material-icons-filled">access-time-filled</i> -->
                                    <b>Минимальный срок аренды:</b>&nbsp;{{ bus.min_time }}&nbsp;ч.</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="order-btn-sect">
                <a @click="$router.push('/order')" class="order"><span>ОСТАВИТЬ ЗАЯВКУ</span></a>
            </div>
        </section>

        <section id="reviews" class="dynamic">
            <h2 class="section-title">Отзывы наших клиентов!</h2>
            
            <swiper class="container">
                <swiper-slide :class="`review review_${index}`" v-for="(review, index) in reviews" :key="index" :ref="`review_${index}`">
                    <h4>{{ review.author }}</h4>
                    <span>{{ this.getMoment(review.date) }}</span>
                    <p>{{ review.content }}</p>
                    <button class="show" @click="showReview(review, 'getReview')">Посмотреть отзыв</button>
                </swiper-slide>
                
            </swiper>
            
            <button @click="showPostModal()">Оставить отзыв</button>
        </section>
        
        <section id="news" class="dynamic">
            <h2 class="section-title">Новости Сатурна</h2>

            <swiper class="container_swiper" v-if="isMobile">
                <swiper-slide class="news" v-for="(item, index) in news" :key="index">
                    <div class="label">
                        <img v-if="item.image" :src="api.apiDomen+item.image" :alt="item.title">
                        <div class="img-label" v-else>
                            <h3>Нет у новости обложки :(</h3>
                        </div>
                        <h3>{{ item.title }}<br>{{ this.getMoment(item.date, "postOrder") }}</h3>
                    </div>
                    <p>{{ item.content }}</p>
                </swiper-slide>
            </swiper>

            <div class="container" v-else>  
                <div class="news" v-for="(item, index) in news" :key="index">
                    <div class="label">
                        <img v-if="item.image" :src="api.apiDomen+item.image" :alt="item.title">
                        <div class="img-label" v-else>
                            <h3>Нет у новости обложки :(</h3>
                        </div>
                        <h3>{{ item.title }}<br>{{ this.getMoment(item.date, "postOrder") }}</h3>
                    </div>
                    <p>{{ item.content }}</p>
                </div>
            </div>

            <div class="order-btn-sect">
                <a href="/order" class="order"><span>ОСТАВИТЬ ЗАЯВКУ</span></a>
            </div>
        </section>
    </main>
    <LoadingComp v-else></LoadingComp>

    <div class="dialog" v-if="postOrderState" ref="postOrder">
        <div class="backdrop" @click="manageModal('postOrder', true)" ></div>
        <div class="container">
            <form @submit="sendForm">
                <label for="name" class="input" style="width: 100%">
                    <h3>Ваше ФИО:</h3>
                    <input type="text" id="name" style="width: 100%" name="name" ref="inpName">
                </label>
                <label for="review" class="input" style="width: 100%">
                    <h3>Отзыв:</h3>
                    <textarea name="review" id="review" ref="inpReview" style="width: 100%" maxlength="120"></textarea>
                </label>
                <label for="file" class="input" style="width: 100%">
                    <h3>Медиаотзыв(необязательно)</h3>
                    <FileInput ref="inpFile" @changed="workWithFiles" :multiple="false" />
                </label>
            
                <button @click="sendForm" class="dialogBtn" type="submit">Оставить отзыв</button>
            </form>
        </div>
    </div>

    <div class="dialog" v-if="getReviewState" ref="getReview">
        <div class="backdrop" @click="manageModal('getReview', true)" ></div>
        <div class="container">
            <div>
                <div class="cont" style="display: flex; flex-wrap: wrap; gap: 16px">
                    <div class="left" style="display: flex; flex-direction: column">
                        <h4>{{ review.author }}</h4>
                        <span>{{ this.getMoment(review.date) }}</span>
                        <p>{{ review.content }}</p>
                    </div>
                    <div class="right" v-if="review.media != ''">
                        <img :src="api.apiDomen+review.media" style="height: 300px" :alt="review.author">
                    </div>
                </div>
                <button class="dialogBtn" @click="manageModal('getReview', true)">Закрыть отзыв</button>
            </div>
        </div>
    </div>

    <FooterComp></FooterComp>
</template>

<script>
import FooterComp from '@/components/footerComp.vue';
import HeaderComp from '@/components/headerComp.vue';
import BannerComp from '@/components/bannerComp.vue';
import LoadingComp from '@/components/loadingComp.vue';
import FileInput from '@/components/fileInput.vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import api from '../api';
const moment = require('moment');

export default {
    name: "landingView",
    components: {
        Swiper,
        SwiperSlide,
        HeaderComp,
        FooterComp,
        BannerComp,
        LoadingComp,
        FileInput
    },
    async mounted() {
        this.bannerSlides = await api.getData('banners') || [];
        this.services = await api.getData('services') || [];
        this.busCollection = await api.getData('cars') || [];
        this.reviews = await api.getData('reviews') || [];
        this.news = await api.getData('news') || [];

        console.log(this.reviews);
        
        if(
            this.busCollection.length == 0 &&
            this.news.length == 0 &&
            this.reviews.length == 0 &&
            this.services.length == 0 &&
            this.bannerSlides.length == 0
        ){
            this.loaded = false
        } else{
            this.loaded = true
        }

        window.addEventListener('resize', this.handleResize);
        this.isMobile = this.isMobileDetect();
    },
    methods: {
        sendForm(event){
            event.preventDefault();
            let formData = new FormData();

            formData.append('author', this.$refs.inpName.value);
            formData.append('content', this.$refs.inpReview.value);
            formData.append('image', this.file);

            api.sendData('review', formData)
            .then(res => {
                if(res.status == 'ok'){
                    alert('Ваш отзыв отправлен и ждет проверки модерации. Желаем Вам хорошего времяпровождения!')
                } else{
                    alert("Оставьте отзыв заново или внесите правки")
                }
            })
        },
        workWithFiles(files){
            this.file = files[0]
        },
        showPostModal() {
            this.postOrderState = true;
        },
        showReview(review, modal) {
            this.review = review;
            this.manageModal(modal);
        },
        manageModal(modal, close = false) {
            console.log(modal);
            if(close) {
                this[`${modal}State`] = false;
            } else{
                this[`${modal}State`] = true;
            }
        },
        isMobileDetect() {
            return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        },
        handleResize() {
            this.isMobile = this.isMobileDetect();
        },
        getMoment(ts) {
            return moment(ts).format("DD.MM.YYYY");
        }
    },
    data() {
        return {
            file: null,
            postOrderState: false,
            getReviewState: false,
            review: {},
            api: api,
            isMobile: false,
            loaded: false,
            bannerSlides: [],
            services: [],
            busCollection: [],
            reviews: [],
            news: []
        };
    }
};
</script>
