import LandingView from '@/views/landingView.vue'
import { createRouter, createWebHistory } from 'vue-router'

const routes = [

  {
    path: '/',
    name: 'Главная страница',
    component: LandingView
  },
  {
    path: '/order',
    name: 'Страница заказа',
    component: () => import(/* webpackChunkName: "order" */ '../views/orderView.vue')
  },
  {
    path : '/:pathMatch(.*)',
    component: () => import(/* webpackChunkName: "order" */ '../views/404.vue')
  },
  
  
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
