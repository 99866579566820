<template>
    <transition>
        <main class="main">
            <section id="loading">
                <h1>Пожалуйста подождите, страница загружается...</h1>
                <div v-if="error != ''" class="container">
                    <h2>{{ error }}<br>Нам очень жаль :(</h2>
                    
                    <a class="order" :href="$route.path">Перезагрузить страницу!</a>
                </div>
            </section>
        </main>
    </transition>
</template>

<script>
    export default {
        name: 'loadingComp',
        data(){
            return{
                time: 0,
                error: ""
            }
        },
        mounted(){
            setTimeout(() => {
                this.error = "Видимо у нашего сервиса появились проблемы или у Вас плохое соедение с интернетом"
            }, 10000);
        }
    }
</script>

<style lang="scss" scoped>
    @import '../assets/styles/__vars.scss';

    .main{
        width: 100%;
        height: 100%;
        background-color: $default-white-color-100;
    }

    #loading{
        flex: 1;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: $default-white-color-100;
        color: $main-color;
        font-family: $font-i;
    }
</style>