<template>
    <header class="info-nav" :style="!isMobile ? 'position: sticky' : null">
        <div class="site_background">
            <div class="blocks half">
                <RouterLink to="/" class="site-logo">
                    <div class="logo" v-if="!isMobile">
                        <img src="/icons/logo.svg" alt="Логотип" class="icon" />
                    </div>
                    <span class="label">
                        <h1>Сатурн</h1>
                        <p>Наши места - до ваших мест</p>
                    </span>
                </RouterLink>
                
                <nav class="site_nav">
                    <ul class="site_nav_list">
                        <li class="site_nav_list-item">
                            <a :href="`mailto:${email}`" class="site_nav_list-item-link">
                                <div class="icon">
                                    <i class="material-icons material-icons-filled">email</i>
                                </div>
                                {{ email }}
                            </a>
                        </li>
                        <i class="border" v-if="!isMobile">|</i>
                        <li class="site_nav_list-item">
                            <a :href="`tel:${phone}`" class="site_nav_list-item-link" :title="phone">
                                <div class="icon"><i class="material-icons material-icons-filled">phone-enabled</i></div>
                                {{ phone }}
                            </a>
                        </li>
                        <i class="border" v-if="!isMobile">|</i>
                        <li class="site_nav_list-item">
                            <a :href="tgLink" class="site_nav_list-item-link" :title="`@${tgLink.split('/')[tgLink.split('/').length - 1]}`">
                                <div class="icon"><i class="material-icons material-icons-filled">telegram></i></div>
                                @{{ tgLink.split('/')[tgLink.split('/').length - 1] }}
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </header>
</template>

<script>
    export default {
        name: "headerComp",
        mounted(){
            window.addEventListener('resize', () => this.isMobile = this.isMobileDetect())
        },
        data(){
            return {
                isMobile: this.isMobileDetect(),
                email: "556066@car65.ru",
                phone: '+7 (4242) 55-60-66',
                tgLink: 'https://t.me/car65ru_bot'
            }
        },
        methods: {
            isMobileDetect() {
                if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                    return true
                } else {
                    return false
                }
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>